<template>
  <div id="Media">
    <b-row>
      <b-col sm="8">
        <div class="card">
          <div slot="header" class="card-header">
            <i class="icon-bubble"></i>
            <span v-if="$route.params.id == '-1'">Blogbeitrag anlegen</span>
            <span v-else>Blogbeitrag bearbeiten</span>
          </div>
          <div v-if="blog" class="card-body">
            <b-form v-on:submit.prevent="onSubmit" novalidate>
              <b-tabs v-model="tabIndex">
                <b-tab active>
                  <template slot="title">
                    <i class="fa fa-table"></i> Stammdaten <span v-if="tabErrors.tab1" class="badge badge-danger"><i
                    class="fa fa-exclamation"></i></span>
                  </template>
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <label for="title">Titel</label>
                        <b-form-input type="text" id="title" v-model="blog.title" placeholder="Titel"
                                      :state="blog.title? true: false"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="8">
                      <b-form-group>
                        <label for="slug">Slug</label>
                        <b-form-input type="text" id="slug" v-model="blog.slug" placeholder="Wenn leer, wird automatisch ein Slug generiert"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <label>Bloghauptkategorie</label>
                      <b-form-select v-model="blog.blogCategory" :options="blogCategoryOptions" value="-1"
                                     :state="blog.blogCategory == -1? false : true"></b-form-select>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col>
                      <label>Secondary Blogkategorien</label>
                      <v-select v-model="blog.subcategories" multiple :close-on-select="false" label="text"
                                :options="blogCategoryOptions"
                                placeholder="Bitte wählen"></v-select>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <label for="date">Datum</label>
                        <b-form-input type="date" id="date" v-model="blog.date" placeholder="Datum"
                                      :state="blog.date? true:false"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <label for="readingTime">Lesezeit in Minuten</label>
                        <b-form-input type="number" id="readingTime" v-model="blog.readingTime"
                                      placeholder="Lesezeit in Minuten"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <label for="author">Author</label>
                        <b-form-select v-model="blog.author" data-required="true" :options="authorOptions" value="-1"
                                       :state="blog.author == -1? false : true"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <label for="video_youtube_id">YouTube Video ID</label>
                        <b-form-input type="text" id="video_youtube_id" v-model="blog.video_youtube_id" placeholder="Video ID">
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <label>Hero-Bild</label><br>
                        <div class="margin-bottom-10">
                          <img v-if="blog.heroImage && blog.heroImage.id > 0"
                               v-bind:src="$store.state.mediaServiceUrl + blog.heroImage.uid + '/200/200'"
                               class="image-placeholder-150">
                        </div>
                        <b-button size="sm" @click="openMediaSelection({ 'mediaType': 'heroImage' })" variant="dark"><i
                          class="fa"></i>Bild auswählen/ändern
                        </b-button>&nbsp;
                        <a class="fa fa-trash fa-lg text-primary" v-if="blog.heroImage && blog.heroImage.id"
                           @click="deleteMedia('heroImage')"></a>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <label>Teaser-Bild</label><br>
                        <div class="margin-bottom-10">
                          <img v-if="blog.teaserImage && blog.teaserImage.id > 0"
                               v-bind:src="$store.state.mediaServiceUrl + blog.teaserImage.uid + '/200/200'"
                               class="image-placeholder-150">
                        </div>
                        <b-button size="sm" @click="openMediaSelection({ 'mediaType': 'teaserImage' })" variant="dark">
                          <i class="fa"></i>Bild auswählen/ändern
                        </b-button>&nbsp;
                        <a class="fa fa-trash fa-lg text-primary" v-if="blog.teaserImage && blog.teaserImage.id"
                           @click="deleteMedia('teaserImage')"></a>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab>
                  <template slot="title">
                    <i class="fa fa-file-text"></i> Inhalt <span v-if="tabErrors.tab1" class="badge badge-danger"><i
                    class="fa fa-exclamation"></i></span>
                  </template>
                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col md="12">
                          <b-button @click="addBlock(1, '12')" class="margin-right-5 mb-1" size="sm" variant="dark"><i
                            class="fa fa-plus"></i> Einspalter
                          </b-button>
                          <b-button @click="addBlock(2, '6/6')" class="margin-right-5 mb-1" size="sm" variant="dark"><i
                            class="fa fa-plus"></i> Zweispalter (50/50)
                          </b-button>
                          <b-button @click="addBlock(2, '4/8')" class="margin-right-5 mb-1" size="sm" variant="dark"><i
                            class="fa fa-plus"></i> Zweispalter (33/66)
                          </b-button>
                          <b-button @click="addBlock(2, '8/4')" class="margin-right-5 mb-1" size="sm" variant="dark"><i
                            class="fa fa-plus"></i> Zweispalter (66/33)
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row v-for="(blogElement, index) in blog.blogElements" :key="index">
                    <BlogElement
                      :blogElement="blogElement"
                      :blogEleIdx="index"
                      :nrOfBlogElements="blog.blogElements.length"
                      @remove="removeBlogElement($event)"
                      @move="moveBlogElement($event)"
                      @openMediaSelection="openMediaSelection($event)"
                    />
                  </b-row>
                </b-tab>
                <b-tab>
                  <template slot="title">
                    <i class="fa fa-binoculars"></i> SEO <span v-if="tabErrors.tab2" class="badge badge-danger"><i
                      class="fa fa-exclamation"></i></span>
                  </template>
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <label for="seo_title">Titel</label>
                        <b-form-input type="text" id="seo_title" v-model="blog.seoTitle" placeholder="SEO Titel"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <label for="seo_description">SEO Description</label>
                        <b-form-textarea id="seo_description" v-model="blog.seoDescription" placeholder="SEO Description" rows="3"></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
              <br>
              <div class="footer-btn" slot="footer">
                <b-button type="submit" stacked size="sm" variant="primary"><i class="fa fa-save"></i> Speichern
                </b-button>&nbsp;
                <b-button size="sm" href="/#/blog/blogEntries/" variant="danger"><i class="fa fa-ban"></i> Abbrechen
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </b-col>
      <MediaSelection
        :show="showMediaSelection"
        :type="mediaType"
        :mime-types="'image/jpeg,image/png'"
        @close="showMediaSelection = false"
        @save="saveMedia($event)"
      />
    </b-row>
  </div>
</template>

<script>

  import Vue from 'vue'
  import MediaSelection from '../Media/MediaSelection'
  import BlogElement from './BlogElement'
  import {mapState, mapGetters} from 'vuex'
  import VueNotifications from "vue-notifications";
  import vSelect from "vue-select";

  const axios = require('axios')

  export default {
    components: {
      MediaSelection,
      BlogElement,
      vSelect,
    },
    name: 'blog',
    data() {
      return {
        blogLoaded: false,
        blogDataChanged: false,
        tabIndex: 0,
        tabErrors: {},
        showMediaSelection: false,
        currentBlockIdx: 0,
        currentColumnIdx: 0,
        mediaType: '',
      }
    },
    computed: {
      ...mapState({
        authorOptions: state => state.blog.person.authorOptions,
        regionOptions: state => state.blog.region.regionOptions,
        blogCategoryOptions: state => state.blogCategory.blogCategoryOptions,
      }),
      ...mapGetters({
        blog: 'blog/getBlog',
        error: 'blog/getError'
      }),

    },
    mounted() {
      if (this.$route.params.id != '-1') {
        this.$store.dispatch('blog/loadBlog', this.$route.params.id)
      } else {
        this.$store.dispatch('blog/initBlog')
      }
    },
    watch: {
      blog: {
        handler: function (val, oldVal) {
          if (!this.blogLoaded) {
            this.blogLoaded = true
          }
          else {
            this.blogDataChanged = true
          }
        },
        deep: true
      }
    },
    beforeRouteLeave(to, from, next) {
      let answer = true
      if (this.blogDataChanged) answer = window.confirm('Wollen Sie zu verlassen? Alle Änderungen verloren werden')
      if (answer) next()
    },
    methods: {
      onSubmit() {
        this.$store.dispatch('blog/saveBlog').then(
          response => {
            if (response.status == 200 || response.status == 201) {
              this.notifySaveSuccess()
              this.blogDataChanged = false
            }
          }, error => {
            this.notifySaveError()
          }
        )
      },

      addBlock(columns, width) {
        let widthList = width.split('/');
        let columnsList = []
        for (let i = 0; i < columns; i++) {
          columnsList.push({size: widthList[i], contentElement: {}})
        }
        let blogElement = {indent: true, columns: columnsList}
        Vue.set(this.blog.blogElements, this.blog.blogElements.length, blogElement)
      },
      removeBlogElement(payload) {
        this.blog.blogElements.splice(payload.index, 1)
      },
      moveBlogElement(payload) {
        let blogEleToMove = this.blog.blogElements[payload.index]
        this.blog.blogElements.splice(payload.index, 1)

        let newIndex = 0
        if (payload.direction == 'up') {
          newIndex = payload.index - 1
        } else {
          newIndex = payload.index + 1;
        }
        this.blog.blogElements.splice(newIndex, 0, blogEleToMove)
      },
      openMediaSelection: function (payload) {
        this.showMediaSelection = true
        this.mediaType = payload.mediaType
        this.mimeType = 'image/jpeg,image/png'
        this.blogEleIdx = payload.blogEleIdx
        this.columnIdx = payload.columnIdx
      },
      saveMedia: function (payload) {
        if (this.mediaType == 'heroImage') {
          this.blog.heroImage = {
            'id': payload.id,
            'uid': payload.uid
          }
        } else if (this.mediaType == 'teaserImage') {
          this.blog.teaserImage = {
            'id': payload.id,
            'uid': payload.uid
          }
        } else {
          switch(this.blog.blogElements[this.blogEleIdx].columns[this.columnIdx].contentElement.type) {
            case 'poi':
            case 'question':
            case 'video':
              this.blog.blogElements[this.blogEleIdx].columns[this.columnIdx].contentElement.content.image = {
                'id': payload.id,
                'uid': payload.uid
              }
              break;
            case 'image_gallery':
              this.blog.blogElements[this.blogEleIdx].columns[this.columnIdx].contentElement.content.push({
                'id': payload.id,
                'uid': payload.uid
              })
              break;
            default:
              this.blog.blogElements[this.blogEleIdx].columns[this.columnIdx].contentElement.content = {
                'id': payload.id,
                'uid': payload.uid
              }
          }
        }
      },
      deleteMedia: function (mediaType) {
        if (mediaType == 'heroImage') {
          this.blog.heroImage = ''
        } else if (mediaType == 'teaserImage') {
          this.blog.teaserImage = ''
        }
      }
    },

    notifications: {
      notifySaveSuccess: {
        type: VueNotifications.types.success,
        title: 'Blogbeitrag wurde erfolgreich gespeichert',
      },
      notifySaveError: {
        type: VueNotifications.types.error,
        title: 'Fehler beim speichern des Blogbeitrags'
      }
    }
  }
</script>
